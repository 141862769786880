export const data = () => ({
  optionsSelected: [],
  options: [
    {
      id: 10,
      name: 'Data',
      children: [
        {
          id: 'data_inicio',
          name: 'De',
        },
        {
          id: 'data_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 20,
      name: 'Contratos',
      children: [
        {
          id: 'servico',
          name: 'Serviço',
        },
        {
          id: 'terminal',
          name: 'Terminal',
        },
      ],
    },
  ],
})
