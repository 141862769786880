<template>
  <v-card class="pa-8">
    <v-overlay v-model="settingFilters">
      <v-progress-circular indeterminate size="32" />
    </v-overlay>
    <v-card-title
      v-if="areSettingsEmpty"
      class="error--text d-flex flex-column justify-center align-center"
    >
      <v-icon color="error">mdi-alert</v-icon>
      <span>Você não selecionou nenhum item nas configurações</span>
      <p class="primary--text">
        Clique no botão <v-icon>mdi-cog</v-icon> para configurar os filtros
      </p>
    </v-card-title>
    <validation-observer v-else-if="!settingFilters" v-slot="{ invalid }">
      <v-card-subtitle class="error--text"
        >Todos os campos abaixo são obrigatórios</v-card-subtitle
      >
      <v-card-text>
        <resumo-movimentacao-list-filtro-modal-item title="Data" v-if="showData">
          <template #items>
            <v-col v-if="params.data_inicio && params.data_inicio.visible">
              <validation-provider
                name="data_inicio"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="De"
                  v-model="params.data_inicio.value"
                  :errors="errors"
                  />
              </validation-provider>
            </v-col>
            <v-col v-if="params.data_fim && params.data_fim.visible">
              <validation-provider
                name="data_fim"
                v-slot="{ errors }"
                rules="required"
              >
                <conferencia-list-filtro-data
                  propLabel="Até"
                  v-model="params.data_fim.value"
                  :errors="errors"
                />
              </validation-provider>
            </v-col>
          </template>
        </resumo-movimentacao-list-filtro-modal-item>

        <resumo-movimentacao-list-filtro-modal-item title="Contratos" v-if="showContratos">
          <template #items>
            <resumo-movimentacao-list-filtro-modal-item-input-terminal
              v-if="params.terminal && params.terminal.visible"
              v-model="params.terminal.value"
              name="terminal"
              label="Terminal"
              icon="mdi-mailbox"
              rules="required"
              :parceiro="params.parceiro.value"
            />

            <resumo-movimentacao-list-filtro-modal-item-servicos
              v-if="params.servico && params.servico.visible"
              v-model="params.servico.value"
              name="servico"
              label="Serviços"
              icon="mdi-home-city"
              rules="required"
              :parceiro="params.parceiro.value"
            />
          </template>
        </resumo-movimentacao-list-filtro-modal-item>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn plain @click="$emit('close')">Cancelar</v-btn>
        <v-btn
          class="px-8"
          x-large
          color="primary"
          @click="search"
          :disabled="areSettingsEmpty || invalid"
          ><v-icon>mdi-database-search</v-icon>
          <span>Buscar</span>
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import { data } from './_data'
import { computed } from './_computed'
import { methods } from './_methods'

import ConferenciaListFiltroData from '@/components/financeiro/conferencia/conferencia-list/ConferenciaListFiltroData'
import ResumoMovimentacaoListFiltroModalItem from './ResumoMovimentacaoListFiltroModalItem.vue'
import ResumoMovimentacaoListFiltroModalItemServicos from './ResumoMovimentacaoListFiltroModalItemServicos.vue'
import ResumoMovimentacaoListFiltroModalItemInputTerminal from './ResumoMovimentacaoListFiltroModalItemInputTerminal.vue'

export default {
  name: 'ServicoListFiltroModal',
  components: {
    ConferenciaListFiltroData,
    ResumoMovimentacaoListFiltroModalItem,
    ResumoMovimentacaoListFiltroModalItemServicos,
    ResumoMovimentacaoListFiltroModalItemInputTerminal,
  },
  data,
  computed,
  methods,
  async created() {
    this.settingFilters = true
    this.getParamsFilter()

    if (this.empresaAtual.gate) {
      this.params.gate.value = this.empresaAtual.public_id
      this.params.proprietario.value = ''
    } else {
      this.params.parceiro.value = this.empresaAtual.gates[0]
      this.params.proprietario.value = this.empresaAtual.public_id
    }

    this.addParamsFilter()

    this.UPDATE_PARAMS(this.params)

    await this.showOnlySelectedOptionsAndGetItems()
    this.settingFilters = false
  },
}
</script>
