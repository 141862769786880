export function data() {
  return {
    settingFilters: false,
    params: {
      data_inicio: {
        value: '',
        visible: false,
      },
      data_fim: {
        value: '',
        visible: false,
      },
      gate: {
        value: '',
        visible: false,
      },
      parceiro: {
        value: '',
        visible: false,
      },
      proprietario: {
        value: '',
        visible: false,
      },
      terminal: {
        value: '',
        visible: false,
      },
      servico: {
        value: null,
        visible: false,
      },
    },
    allOptions: ['data_inicio', 'data_fim', 'terminal', 'servico'],
  }
}
