var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-overlay',{model:{value:(_vm.carregandoDados),callback:function ($$v) {_vm.carregandoDados=$$v},expression:"carregandoDados"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.registros,"footer-props":{
        itemsPerPageAllText: ("Todos (" + (_vm.registros.length) + ")"),
        itemsPerPageOptions: [50, 100, 150, 200, -1],
      }},scopedSlots:_vm._u([{key:"item.valor",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor) || _vm.toReal(0))+" ")]}},_vm._l((_vm.headers.filter(function (header) { return header.servico; })),function(header){return {key:("item." + (header.value)),fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item[header.value].valor))+" "),_c('v-divider',{key:header,staticClass:"mx-2",attrs:{"vertical":"","inset":""}}),_vm._v(" N° Cms "+_vm._s(item[header.value].quantidade)+" ")]}}})],null,true)}),_c('download-excel',{attrs:{"name":"servicosParceiros.xls","data":_vm.registros,"fields":_vm.fields_excel}},[_c('v-btn',{staticClass:"w-100 mt-5",attrs:{"color":"info"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":"","dark":""}},[_vm._v(" mdi-download ")]),_vm._v(" Baixar Planilha")],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }