import { mapActions, mapMutations } from 'vuex'

export const methods = {
  ...mapActions('contratos', ['getContratos']),
  ...mapMutations('resumoMovimentacao', ['UPDATE_PARAMS']),

  handleFixParams(fixedParams, [key, param]) {
    if (
      param.value !== null &&
      param.value !== '' &&
      param.value !== undefined
    ) {
      const paramsWithServico = ['servico']

      if (paramsWithServico.includes(key))
        fixedParams[key] = this.params.servico.value.map((r) => r.id).toString()
      else fixedParams[key] = param.value
    }
    return fixedParams
  },
  fixParams() {
    return Object.entries(this.params).reduce(
      this.handleFixParams,
      new Object()
    )
  },

  addParamsFilter() {
    localStorage.setItem(
      'filterOptionsDetailsResumoMovimentacao',
      JSON.stringify(this.params)
    )
  },

  getParamsFilter() {
    if (localStorage.getItem('filterOptionsDetailsResumoMovimentacao')) {
      this.params = JSON.parse(
        localStorage.getItem('filterOptionsDetailsResumoMovimentacao')
      )
    }
  },

  search() {
    if (!this.areSettingsEmpty) {
      const fixedParams = { ...this.fixParams() }
      this.UPDATE_PARAMS(fixedParams)
      this.addParamsFilter()
      this.$emit('search')
    }
  },

  async showOnlySelectedOptionsAndGetItems() {
    if (localStorage.getItem('filterOptionsDetailsResumoMovimentacao')) {
      this.params = JSON.parse(
        localStorage.getItem('filterOptionsDetailsResumoMovimentacao')
      )
    }
    this.allOptions.forEach((someOption) => {
      if (this.params[someOption]) {
        this.params[someOption].visible = false
      }
    })
    this.selectedOptions.forEach((option) => {
      this.params[option].visible = true
    })
    this.allOptions.forEach((someOption) => {
      if (this.params[someOption]?.visible == false) {
        this.params[someOption].value = ''
      }
    })
    this.addParamsFilter()
  },
}
