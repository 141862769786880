<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <resumo-movimentacao-list-filtro @search="handleGetReport" />
      </v-col>
    </v-row>

    <v-card class="pa-2 mt-5">
      <v-tabs v-model="tab">
        <v-tab href="#listagem" @change="carregarConteudo('listagem')"
          >Serviços Realizado</v-tab
        >
        <v-tabs-items v-model="tab" class="mt-2">
          <v-tab-item eager value="listagem">
            <v-row>
              <v-col cols="12" sm="12">
                <BaseCard>
                  <listagem-resumo-movimentacao
                    ref="listagem"
                  ></listagem-resumo-movimentacao>
                </BaseCard>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import ResumoMovimentacaoListFiltro from '@/components/financeiro/resumo-movimentacao/relatorio/ResumoMovimentacaoListFiltro.vue'
import ListagemResumoMovimentacao from '@/components/financeiro/resumo-movimentacao/relatorio/Listagem.vue'
import {mapState} from 'vuex'

import { toReal, parseDatetime } from '@/mixins/convertion.js'
export default {
  name: 'ParceirosRelatorio',

  components: {
    ListagemResumoMovimentacao,
    ResumoMovimentacaoListFiltro,
  },
  mixins: [toReal, parseDatetime],
  data() {
    return {
      tab: 'listagem',
      page: {
        title: 'Relatório de movimentação',
      },
      breadcrumbs: [
        {
          text: 'Financeiro',
          disabled: true,
        },
        {
          text: 'Movimentação',
          disabled: true,
        },
        {
          text: 'Relatório',
          disabled: false,
        },
      ],
    }
  },
  created() {
      
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {
    async carregarConteudo(tab) {
      this.tab = tab
      await this.$refs[tab].getReport()
    },
    async handleGetReport() {
      await this.carregarConteudo(this.tab)
    },
  },
}
</script>
