<template>
  <v-col>
    <validation-provider v-slot="{ errors }" :rules="rules" :name="name">
      <v-autocomplete
        :items="terminaisSelecionados"
        :error-messages="errors"
        :label="label"
        :prepend-icon="icon"
        v-model="model"
        item-text="trade_name"
        multiple
        return-object
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.trade_name"></v-list-item-title>
            <v-list-item-subtitle
              v-text="`CNPJ ${item.cnpj} - ${item.city} - ${item.district}`"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </validation-provider>
  </v-col>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ResumoMovimentacaoListFiltroModalItemInputTerminal',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      required: true,
    },
    parceiro: {
      type: [Object, String],
      required: true,
    }
  },
  async created() {
    this.empresaAtual.gate ? await this.buscarTerminaisFornecedores() : await this.buscarTerminaisFornecedores(this.parceiro)
  },
 methods: {
    ...mapActions('contratos', ['buscarTerminaisFornecedores']),
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('contratos', ['terminaisFornecedores']),

    terminaisSelecionados() {
      return  this.terminaisFornecedores.filter((t) =>
        this.parceiro?.parceria?.terminais?.includes(t.public_id)
      )
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
